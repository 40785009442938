import React from 'react';
import Masthead from '../../components/Masthead';

const RegistryOverview = () => {
	return (
		<div className='about'>
			<Masthead heading1='About' heading2='Registry Overview' />
			<p className='left-full'>
				The Texas School Safety and Security Consultant Registry (Registry)
				consists of registered Consultants who have been vetted to provide
				school safety or security consulting services in Texas. The Registry is
				not company-based but Consultants may list the name of the company they
				are affiliated with during the registration process. School districts
				are required to confirm that any person providing safety or security
				consulting services are included on the Registry, regardless of their
				company affiliation.
			</p>

			<p>
				Consultants are subject to ongoing review of qualifications and the
				Texas School Safety Center (TxSSC) may require individuals to update
				their information as needed to comply with legislative updates, system
				updates, and other Registry requirements. Consultants are required to
				renew biennially to maintain their status on the Registry.
			</p>

			<div className='callout'>
				<h3>Basic requirements for inclusion on the Registry:</h3>
				<ul>
					<li>
						Must be at least 18 years of age, as defined by{' '}
						<a
							href='https://statutes.capitol.texas.gov/docs/cp/htm/cp.129.htm'
							target='_blank'
							rel='noopener noreferrer'>
							Civil Practice and Remedies Code 129.001
						</a>
					</li>
					<li>
						Must follow the Application Process outlined in the{' '}
						<a href='/registration-process'>Registration Process</a>
					</li>
					<li>Must pass a background check</li>
					<li>
						Must meet the basic training requirements for each Discipline being
						sought by the Applicant
					</li>
				</ul>
			</div>

			<p className='left-full'>
				Upon review and approval of a General Application, Applicants will
				receive the background check instructions and required service code
				unique to the TxSSC.
			</p>

			<p className='left-full'>
				If an Applicant's background check does not exclude them from the
				Registry, Applicants must apply to one or more of the specific
				Disciplines listed below:
			</p>

			<ol>
				<li>
					<a
						href='/disciplines/audit'
						target='_blank'
						rel='noopener noreferrer'>
						<b>Audit Discipline</b>
					</a>
				</li>
				<li>
					<a href='/disciplines/eop' target='_blank' rel='noopener noreferrer'>
						<b>Multi-Hazard Emergency Operations Plan (EOP) Discipline</b>
					</a>
				</li>
			</ol>

			<p className='left-full'>
				Additionally, Applicants will select the region(s) of the state where
				they are willing to provide consulting services. These regions are
				defined by the State Board of Education and can be viewed below:
			</p>

			<div className='embed-container'>
				<iframe
					width='500'
					height='400'
					frameBorder='0'
					scrolling='no'
					marginHeight='0'
					marginWidth='0'
					title='Registry Regions Website'
					src='//txst.maps.arcgis.com/apps/Embed/index.html?webmap=da26b3ef31ac45c98cbfde2c51ef9ba2&extent=-108.4606,21.5679,-92.7721,37.8335&zoom=true&previewImage=false&scale=true&search=true&searchextent=true&disable_scroll=true&theme=dark'
				/>
			</div>
		</div>
	);
};

export default RegistryOverview;
