import React from 'react';
import Masthead from '../../components/Masthead';
import { Link } from 'react-router-dom';
const Audit = () => {
	return (
		<div className='disciplines'>
			<Masthead heading1='Disciplines' heading2='Audit Discipline' />

			<p className='left-full'>
				An Audit Consultant may perform safety and security audits for
				independent school districts, open-enrollment charter schools, and
				junior colleges to meet the standards established by the Texas School
				Safety Center and in compliance with Chapter 37 of the Texas Education
				Code. The Audit Consultant may recommend improvements to a district's
				physical security infrastructure.
			</p>

			<p className='left-full'>
				As part of the audit process, the Audit Consultant may review whether a
				district has a Multi-Hazard Emergency Operations Plan (EOP) and whether
				the EOP sufficiently covers all the district hazards and functions.
				However, an Audit Consultant may not provide guidance, development,
				implementation, or consulting for EOPs unless they are also registered
				for the Multi-Hazard Emergency Operations Plan Discipline.
			</p>

			<p className='left-full'>
				Applicants to the Texas School Safety and Security Consultant Registry
				must provide information regarding their background, education, and
				experience that is relevant to their ability to provide knowledgeable
				and effective school safety or security consulting services.
			</p>

			<p className='left-full'>
				Applicants must show their Audit Discipline qualifications through
				either the <b>Credential Pathway</b> or <b>Experience Pathway</b>. Both
				Pathways require demonstrated experience that will be verified by the
				references provided during the{' '}
				<Link
					to='/registration-process'
					target='_blank'
					rel='noopener noreferrer'>
					<b>Registration Process</b>
				</Link>
				.
			</p>

			<div className='callout numbered'>
				<h3>Credential Pathway</h3>
				<p className='left-full'>
					The Credential Pathway requires proof of pre-identified certifications
					and at least one (1) year of demonstrated experience in Texas since
					2019.
				</p>

				<h4 className='no-margin-bottom'>Required Documentation</h4>
				<ol style={{ listStyleType: 'decimal' }}>
					<li>
						Applicants are required to provide a qualified reference that can
						verify at least one (1) year of demonstrated experience since 2019.
						Please visit{' '}
						<Link
							to='/about/references'
							target='_blank'
							rel='noopener noreferrer'>
							<b>About-References</b>
						</Link>{' '}
						for more information.
					</li>
					<li>
						Applicants are required to provide proof of an active certification
						in one (1) of the following:
						<ul>
							<li>
								<i
									class='fa fa-external-link download-link'
									aria-hidden='true'></i>
								<a
									href='https://www.asisonline.org/certification/associate-protection-professional-app/'
									target='_blank'
									rel='noopener noreferrer'>
									<b>Associate Protection Professional:</b> American Society for
									Industrial Security (ASIS)
								</a>
							</li>
							<li>
								<i
									class='fa fa-external-link download-link'
									aria-hidden='true'></i>
								<a
									href='https://www.asisonline.org/certification/certified-protection-professional-cpp/'
									target='_blank'
									rel='noopener noreferrer'>
									<b>Certified Protection Professional:</b> American Society for
									Industrial Security (ASIS)
								</a>
							</li>
							<li>
								<i
									class='fa fa-external-link download-link'
									aria-hidden='true'></i>
								<a
									href='https://www.asisonline.org/certification/physical-security-professional/'
									target='_blank'
									rel='noopener noreferrer'>
									<b>Physical Security Professional Certification:</b> American
									Society for Industrial Security (ASIS)
								</a>
							</li>
							<li>
								<i
									class='fa fa-external-link download-link'
									aria-hidden='true'></i>
								<a
									href='https://www.iaem.org/certification/intro'
									target='_blank'
									rel='noopener noreferrer'>
									<b>Certified Emergency Manager:</b> International Association
									of Emergency Managers (IAEM)
								</a>
							</li>
							<li>
								<i
									class='fa fa-external-link download-link'
									aria-hidden='true'></i>
								<a
									href='https://www.emat-tx.org/TEM'
									target='_blank'
									rel='noopener noreferrer'>
									<b>Texas Emergency Manager:</b> Emergency Management
									Association of Texas (EMAT)
								</a>
							</li>
							<li>
								<i
									class='fa fa-external-link download-link'
									aria-hidden='true'></i>
								<a
									href='https://teex.org/program/infrastructure-protection/'
									target='_blank'
									rel='noopener noreferrer'>
									<b>Infrastructure Protection Certificate:</b> Texas A&M
									Engineering Extension Service (TEEX)
								</a>
							</li>
							<li>
								<i
									class='fa fa-external-link download-link'
									aria-hidden='true'></i>
								<a
									href='https://training.fema.gov/programs/emergency-management-professional/basic/'
									target='_blank'
									rel='noopener noreferrer'>
									<b>National Emergency Management Basic Academy:</b> Emergency
									Management Institute (FEMA)
								</a>
							</li>
							<li>
								<i
									class='fa fa-external-link download-link'
									aria-hidden='true'></i>
								<a
									href='https://training.fema.gov/programs/emergency-management-professional/advanced/'
									target='_blank'
									rel='noopener noreferrer'>
									<b>National Emergency Management Advanced Academy:</b>{' '}
									Emergency Management Institute (FEMA)
								</a>
							</li>
							<li>
								<i
									class='fa fa-external-link download-link'
									aria-hidden='true'></i>
								<a
									href='https://training.fema.gov/programs/emergency-management-professional/executive/'
									target='_blank'
									rel='noopener noreferrer'>
									<b>National Emergency Management Executive Academy:</b>{' '}
									Emergency Management Institute (FEMA)
								</a>
							</li>
							<li>
								<i
									class='fa fa-external-link download-link'
									aria-hidden='true'></i>
								<a
									href='https://thenicp.com/cpted-professional-designation/'
									target='_blank'
									rel='noopener noreferrer'>
									<b>
										Crime Prevention Through Environmental Design Professional
										Designation:
									</b>{' '}
									National Institute of Crime Prevention (NICP)
								</a>
							</li>
							<li>
								<i
									class='fa fa-external-link download-link'
									aria-hidden='true'></i>
								<a
									href='https://www.fletc.gov/training-program/physical-security-training-program'
									target='_blank'
									rel='noopener noreferrer'>
									<b>Physical Security Training Program:</b> Federal Law
									Enforcement Training Center (FLETC)
								</a>
							</li>
						</ul>
					</li>
					<li>
						Applicants are required to attest that they will follow{' '}
						<a
							href='https://statutes.capitol.texas.gov/docs/ed/htm/ed.37.htm#37.108'
							target='_blank'
							rel='noopener noreferrer'>
							TEC 37.108(b)
						</a>{' '}
						and utilize the{' '}
						<a
							href='https://txssc.txstate.edu/tools/ss-audit-toolkit/'
							target='_blank'
							rel='noopener noreferrer'>
							School Safety and Security Audit Toolkit
						</a>
						.
					</li>
					<li>
						Applicants are required to attest that they understand the
						consulting services they provide may directly impact a district's
						ability to properly complete their legislatively mandated {''}
						<a
							href='https://txssc.txstate.edu/research/reports/dar-2023/'
							target='_blank'
							rel='noopener noreferrer'>
							District Audit Report
						</a>
						,{' '}
						<a
							href='https://txssc.txstate.edu/research/reports/car-2022/'
							target='_blank'
							rel='noopener noreferrer'>
							Charter Audit Report
						</a>
						, or {''}
						<a
							href='https://txssc.txstate.edu/research/reports/jcar-2024/'
							target='_blank'
							rel='noopener noreferrer'>
							Junior College Audit Report
						</a>
						.
					</li>
				</ol>
			</div>

			<div className='callout numbered'>
				<h3>Experience Pathway</h3>
				<p className='left-full'>
					For Applicants who may not hold current certification(s) listed in the
					Credential Pathway, you may qualify under the Experience Pathway with
					proof of at least five years of demonstrated experience in Texas,
					since 2019.
				</p>

				<h4 className='no-margin-bottom'>Required Documentation</h4>
				<ol style={{ listStyleType: 'decimal' }}>
					<li>
						Applicants are required to provide a qualified reference that can
						verify at least five (5) years of demonstrated experience since
						2019. Please visit{' '}
						<Link to='/about/references'>
							<b>About-References</b>
						</Link>{' '}
						for more information.
					</li>

					<li>
						Applicants are required to attest that they will follow{' '}
						<a
							href='https://statutes.capitol.texas.gov/docs/ed/htm/ed.37.htm#37.108'
							target='_blank'
							rel='noopener noreferrer'>
							TEC 37.108(b)
						</a>{' '}
						and utilize the{' '}
						<a
							href='https://txssc.txstate.edu/tools/ss-audit-toolkit/'
							target='_blank'
							rel='noopener noreferrer'>
							School Safety and Security Audit Toolkit
						</a>
						.
					</li>
					<li>
						Applicants are required to attest that they understand the
						consulting services they provide may directly impact a district's
						ability to properly complete their legislatively mandated {''}
						<a
							href='https://txssc.txstate.edu/research/reports/dar-2023/'
							target='_blank'
							rel='noopener noreferrer'>
							District Audit Report
						</a>
						,{' '}
						<a
							href='https://txssc.txstate.edu/research/reports/car-2022/'
							target='_blank'
							rel='noopener noreferrer'>
							Charter Audit Report
						</a>
						, or {''}
						<a
							href='https://txssc.txstate.edu/research/reports/jcar-2024/'
							target='_blank'
							rel='noopener noreferrer'>
							Junior College Audit Report
						</a>
						.
					</li>
				</ol>
			</div>
		</div>
	);
};

export default Audit;
