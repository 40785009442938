import React from 'react';
import Masthead from '../../components/Masthead';
import { Link } from 'react-router-dom';

const EOP = () => {
	return (
		<div className='disciplines'>
			<Masthead
				heading1='Disciplines'
				heading2='Multi-Hazard Emergency Operations Plan (EOP) Discipline'
			/>

			<p className='left-full'>
				An Emergency Operations Plan (EOP) Consultant may review, develop, or
				modify EOPs to address all the district hazards and functions as well as
				provide emergency management consulting for independent school
				districts, open-enrollment charter schools, and junior colleges to meet
				the standards established by the Texas School Safety Center and in
				compliance with Chapter 37 of the Texas Education Code.
			</p>

			<p className='left-full'>
				The EOP Consultant may review the results of any Audit performed to
				guide the EOP process. However, an EOP Consultant may not perform safety
				and security audits unless they are also registered for the Audit
				Discipline.
			</p>

			<p className='left-full'>
				Applicants to the Texas School Safety and Security Consultant Registry
				must provide information regarding their background, education, and
				experience that is relevant to their ability to provide knowledgeable
				and effective school safety or security consulting services.
			</p>

			<p className='left-full'>
				Applicants must show their EOP Discipline qualifications through either
				the <b>Credential Pathway</b> or <b>Experience Pathway</b>. Both
				Pathways require demonstrated experience that will be verified by the
				references provided during the{' '}
				<Link
					to='/registration-process'
					target='_blank'
					rel='noopener noreferrer'>
					<b>Registration Process</b>
				</Link>
				.
			</p>

			<div className='callout numbered'>
				<h3>Credential Pathway</h3>
				<p className='left-full'>
					The Credential Pathway requires proof of pre-identified certifications
					and at least one (1) year of demonstrated experience in Texas since
					2019.
				</p>

				<h4 className='no-margin-bottom'>Required Documentation</h4>
				<ol style={{ listStyleType: 'decimal' }}>
					<li>
						Applicants are required to provide a qualified reference that can
						verify at least one (1) year of demonstrated experience since 2019.
						Please visit{' '}
						<Link
							to='/about/references'
							target='_blank'
							rel='noopener noreferrer'>
							<b>About-References</b>
						</Link>{' '}
						for more information.
					</li>
					<li>
						Applicants are required to provide proof of an active certification
						in one (1) of the following:
						<ul>
							<li>
								<i
									class='fa fa-external-link download-link'
									aria-hidden='true'></i>
								<a
									href='https://www.asisonline.org/certification/associate-protection-professional-app/'
									target='_blank'
									rel='noopener noreferrer'>
									<b>Associate Protection Professional:</b> American Society for
									Industrial Security (ASIS)
								</a>
							</li>
							<li>
								<i
									class='fa fa-external-link download-link'
									aria-hidden='true'></i>
								<a
									href='https://www.asisonline.org/certification/certified-protection-professional-cpp/'
									target='_blank'
									rel='noopener noreferrer'>
									<b>Certified Protection Professional:</b> American Society for
									Industrial Security (ASIS)
								</a>
							</li>
							<li>
								<i
									class='fa fa-external-link download-link'
									aria-hidden='true'></i>
								<a
									href='https://www.asisonline.org/certification/physical-security-professional/'
									target='_blank'
									rel='noopener noreferrer'>
									<b>Physical Security Professional Certification:</b> American
									Society for Industrial Security (ASIS)
								</a>
							</li>
							<li>
								<i
									class='fa fa-external-link download-link'
									aria-hidden='true'></i>
								<a
									href='https://www.iaem.org/certification/intro'
									target='_blank'
									rel='noopener noreferrer'>
									<b>Certified Emergency Manager:</b> International Association
									of Emergency Managers (IAEM)
								</a>
							</li>
							<li>
								<i
									class='fa fa-external-link download-link'
									aria-hidden='true'></i>
								<a
									href='https://www.emat-tx.org/TEM'
									target='_blank'
									rel='noopener noreferrer'>
									<b>Texas Emergency Manager:</b> Emergency Management
									Association of Texas (EMAT)
								</a>
							</li>
							<li>
								<i
									class='fa fa-external-link download-link'
									aria-hidden='true'></i>
								<a
									href='https://training.fema.gov/programs/emergency-management-professional/basic/'
									target='_blank'
									rel='noopener noreferrer'>
									<b>National Emergency Management Basic Academy:</b> Emergency
									Management Institute (FEMA)
								</a>
							</li>
							<li>
								<i
									class='fa fa-external-link download-link'
									aria-hidden='true'></i>
								<a
									href='https://training.fema.gov/programs/emergency-management-professional/basic/'
									target='_blank'
									rel='noopener noreferrer'>
									<b>National Emergency Management Basic Academy:</b> Emergency
									Management Institute (FEMA)
								</a>
							</li>
							<li>
								<i
									class='fa fa-external-link download-link'
									aria-hidden='true'></i>
								<a
									href='https://training.fema.gov/programs/emergency-management-professional/basic/'
									target='_blank'
									rel='noopener noreferrer'>
									<b>National Emergency Management Advanced Academy:</b>{' '}
									Emergency Management Institute (FEMA)
								</a>
							</li>
						</ul>
					</li>
					<li>
						Applicants are required to provide proof that the following five (5)
						courses have been completed:
						<ul>
							<li>
								<i
									className='fa fa-external-link download-link'
									aria-hidden='true'></i>
								<a
									href='https://training.fema.gov/is/courseoverview.aspx?code=is-100.c&lang=en'
									target='_blank'
									rel='noopener noreferrer'>
									<b>FEMA IS-100:</b> Introduction to the Incident Command
									System
								</a>
							</li>
							<li>
								<i
									className='fa fa-external-link download-link'
									aria-hidden='true'></i>
								<a
									href='https://training.fema.gov/is/courseoverview.aspx?code=is-360&lang=en'
									target='_blank'
									rel='noopener noreferrer'>
									<b>FEMA IS-360:</b> Preparing for Mass Casualty Incidents: A
									Guide for Schools, Higher Education, and Houses of Worship
								</a>
							</li>
							<li>
								<i
									className='fa fa-external-link download-link'
									aria-hidden='true'></i>
								<a
									href='https://training.fema.gov/is/courseoverview.aspx?code=is-362.a&lang=en'
									target='_blank'
									rel='noopener noreferrer'>
									<b>FEMA IS-362.A:</b> Multi-Hazard Emergency Planning for
									Schools
								</a>
							</li>
							<li>
								<i
									className='fa fa-external-link download-link'
									aria-hidden='true'></i>
								<a
									href='https://training.fema.gov/is/courseoverview.aspx?code=IS-363&lang=en'
									target='_blank'
									rel='noopener noreferrer'>
									<b>FEMA IS-363:</b> Introduction to Emergency Management for
									Higher Education
								</a>
							</li>
							<li>
								<i
									className='fa fa-external-link download-link'
									aria-hidden='true'></i>
								<a
									href='https://training.fema.gov/is/courseoverview.aspx?code=IS-700.b&lang=en'
									target='_blank'
									rel='noopener noreferrer'>
									<b>FEMA IS-700:</b> Introduction to the National Incident
									Management System
								</a>
							</li>
						</ul>
					</li>
					<li>
						Applicants are required to attest that they will follow{' '}
						<a
							href='https://statutes.capitol.texas.gov/docs/ed/htm/ed.37.htm#37.108'
							target='_blank'
							rel='noopener noreferrer'>
							TEC 37.108
						</a>{' '}
						and utilize the{' '}
						<a
							href='https://txssc.txstate.edu/tools/bp-toolkit'
							target='_blank'
							rel='noopener noreferrer'>
							EOP Basic Plan Toolkit
						</a>
						.
					</li>
					<li>
						Applicants are required to attest that they understand the
						consulting services they provide may directly impact a district's
						ability to properly complete their legislatively mandated {''}
						<a
							href='https://txssc.txstate.edu/research/reports/dar-2023/'
							target='_blank'
							rel='noopener noreferrer'>
							District Audit Report
						</a>
						,{' '}
						<a
							href='https://txssc.txstate.edu/research/reports/car-2022/'
							target='_blank'
							rel='noopener noreferrer'>
							Charter Audit Report
						</a>
						, or {''}
						<a
							href='https://txssc.txstate.edu/research/reports/jcar-2024/'
							target='_blank'
							rel='noopener noreferrer'>
							Junior College Audit Report
						</a>
						.
					</li>
				</ol>
			</div>

			<div className='callout numbered'>
				<h3>Experience Pathway</h3>
				<p className='left-full'>
					For Applicants who may not hold current certification(s) listed in the
					Credential Pathway, you may qualify under the Experience Pathway with
					proof of at least five years of demonstrated experience in Texas,
					since 2019.
				</p>

				<h4 className='no-margin-bottom'>Required Documentation</h4>
				<ol style={{ listStyleType: 'decimal' }}>
					<li>
						Applicants are required to provide a qualified reference that can
						verify at least five (5) years of demonstrated experience since
						2019. Please visit{' '}
						<Link to='/about/references'>
							<b>About-References</b>
						</Link>{' '}
						for more information.
					</li>
					<li>
						Applicants are required to provide proof that the following five (5)
						courses have been completed:
						<ul>
							<li>
								<i
									className='fa fa-external-link download-link'
									aria-hidden='true'></i>
								<a
									href='https://training.fema.gov/is/courseoverview.aspx?code=is-100.c&lang=en'
									target='_blank'
									rel='noopener noreferrer'>
									<b>FEMA IS-100:</b> Introduction to the Incident Command
									System
								</a>
							</li>
							<li>
								<i
									className='fa fa-external-link download-link'
									aria-hidden='true'></i>
								<a
									href='https://training.fema.gov/is/courseoverview.aspx?code=is-360&lang=en'
									target='_blank'
									rel='noopener noreferrer'>
									<b>FEMA IS-360:</b> Preparing for Mass Casualty Incidents: A
									Guide for Schools, Higher Education, and Houses of Worship
								</a>
							</li>
							<li>
								<i
									className='fa fa-external-link download-link'
									aria-hidden='true'></i>
								<a
									href='https://training.fema.gov/is/courseoverview.aspx?code=is-362.a&lang=en'
									target='_blank'
									rel='noopener noreferrer'>
									<b>FEMA IS-362.A:</b> Multi-Hazard Emergency Planning for
									Schools
								</a>
							</li>
							<li>
								<i
									className='fa fa-external-link download-link'
									aria-hidden='true'></i>
								<a
									href='https://training.fema.gov/is/courseoverview.aspx?code=IS-363&lang=en'
									target='_blank'
									rel='noopener noreferrer'>
									<b>FEMA IS-363:</b> Introduction to Emergency Management for
									Higher Education
								</a>
							</li>
							<li>
								<i
									className='fa fa-external-link download-link'
									aria-hidden='true'></i>
								<a
									href='https://training.fema.gov/is/courseoverview.aspx?code=IS-700.b&lang=en'
									target='_blank'
									rel='noopener noreferrer'>
									<b>FEMA IS-700:</b> Introduction to the National Incident
									Management System
								</a>
							</li>
						</ul>
					</li>

					<li>
						Applicants are required to attest that they will follow{' '}
						<a
							href='https://statutes.capitol.texas.gov/docs/ed/htm/ed.37.htm#37.108'
							target='_blank'
							rel='noopener noreferrer'>
							TEC 37.108
						</a>{' '}
						and utilize the{' '}
						<a
							href='https://txssc.txstate.edu/tools/bp-toolkit'
							target='_blank'
							rel='noopener noreferrer'>
							EOP Basic Plan Toolkit
						</a>
						.
					</li>
					<li>
						Applicants are required to attest that they understand the
						consulting services they provide may directly impact a district's
						ability to properly complete their legislatively mandated {''}
						<a
							href='https://txssc.txstate.edu/research/reports/dar-2023/'
							target='_blank'
							rel='noopener noreferrer'>
							District Audit Report
						</a>
						,{' '}
						<a
							href='https://txssc.txstate.edu/research/reports/car-2022/'
							target='_blank'
							rel='noopener noreferrer'>
							Charter Audit Report
						</a>
						, or {''}
						<a
							href='https://txssc.txstate.edu/research/reports/jcar-2024/'
							target='_blank'
							rel='noopener noreferrer'>
							Junior College Audit Report
						</a>
						.
					</li>
				</ol>
			</div>
		</div>
	);
};

export default EOP;
