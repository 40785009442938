import React from 'react';
import Masthead from '../../components/Masthead';

const DistrictOverview = () => {
	return (
		<div className='about'>
			<Masthead heading1='About' heading2='District Overview' />

			<p className='left-full'>
				As mandated by{' '}
				<a
					href='https://statutes.capitol.texas.gov/docs/ED/htm/ED.37.htm'
					target='_blank'
					rel='noopener noreferrer'>
					<b>Texas Education Code 37.2091</b>
				</a>
				, any person providing school safety or security consulting services to
				independent school districts, open-enrollment charter schools, or junior
				colleges in Texas must register with the Texas School Safety Center
				(TxSSC). School safety or security consulting services include providing
				advice, information, recommendations, data collection, or safety and
				security audit services relevant to school safety and security.
			</p>

			<p className='left-full'>
				The Registry is designed to connect independent school districts,
				open-enrollment charter schools, and junior colleges with registered
				Consultants who have been vetted to provide school safety or security
				consulting services in Texas. The Registry is not company-based but
				consultants may list the name of the company they are affiliated with.
			</p>

			<p className='left-full'>
				Districts can use search filters to identify Consultants by name,
				service(s) provided, or certification(s).
			</p>

			<p className='left-full'>
				The TxSSC does not endorse any Consultants on the Registry. It is the
				responsibility of the district to ensure the Consultant can perform the
				work as required by law, as needed by the district, and to the standards
				required by the district or by law.
			</p>

			<div className='callout'>
				<h3>Registered Consultants have been vetted for the following:</h3>
				<ul>
					<li>Demonstrated experience</li>
					<li>Fingerprint-based criminal history review</li>
					<li>National Sex Offender Public Website review </li>
					<li>Texas Education Agency Do Not Hire Registry review</li>
					<li>State Board for Educator Certification review</li>
					<li>State and national litigation review</li>
				</ul>
			</div>
		</div>
	);
};

export default DistrictOverview;
