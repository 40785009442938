import React from 'react';
import Masthead from '../../components/Masthead';
import useExpander from '../../hooks/useExpander';

const Fy25Renewal = () => {
	const [expandedSections, toggleSection] = useExpander();

	const faqSections = [
		{
			title:
				'I am credentialed in Audit, EOP, SBTA, and Other, will my profile be removed from the Registry on June 1, 2025?',
			content: `
				No. Your profile will remain on the public search; however, only the Audit Discipline and EOP Discipline will be displayed. The Registry will no longer support the Disciplines of SBTA and Other and will be removed from your profile on June 1, 2025. If you do not register for the <a href="/disciplines/audit" target="_blank" rel="noopener noreferrer"><b>Audit</b></a>, <a href="/disciplines/eop" target="_blank" rel="noopener noreferrer"><b>Emergency Operations Plan (EOP)</b></a>, or both under the updated requirements by September 1, 2025, your profile will be removed from the public search, and you will no longer be an actively registered Consultant on the Registry. 
				`,
		},
		{
			title:
				'Will I be removed from the Registry if I am only credentialed in the Other Discipline?',
			content: `
				Yes. The Registry will no longer support the Other Discipline, and your profile will be removed from the public search on June 1, 2025.  You will no longer be an actively registered Consultant on the Registry. Actively registered Consultants must be credentialed in the Discipline(s) of <a href="/disciplines/audit" target="_blank" rel="noopener noreferrer"><b>Audit</b></a>, <a href="/disciplines/eop" target="_blank" rel="noopener noreferrer"><b>Emergency Operations Plan (EOP)</b></a>, or both under the updated requirements by September 1, 2025.
			`,
		},
		{
			title:
				'Will I be removed from the Registry if I am only credentialed in the SBTA Discipline?',
			content: `
				Yes. The Registry will no longer support the SBTA Discipline, and your profile will be removed from the public search on June 1, 2025.  You will no longer be an actively registered Consultant on the Registry. Actively registered Consultants must be credentialed in the Discipline(s) of <a href="/disciplines/audit" target="_blank" rel="noopener noreferrer"><b>Audit</b></a>, <a href="/disciplines/eop" target="_blank" rel="noopener noreferrer"><b>Emergency Operations Plan (EOP)</b></a>, or both under the updated requirements by September 1, 2025.
			`,
		},
		{
			title:
				'What if I do not have experience with independent school districts, open-enrollment charter schools, and junior colleges in Texas?',
			content: `
				If you do not have experience with independent school district, open-enrollment charter schools, junior colleges, universities, private schools, or licensed center-based childcare facilities (excluding home-based facilities) in Texas, you will not meet the eligibility requirements to be listed as a Consultant on the Registry. The Registry is designed to verify experience specifically within these educational settings to ensure that only qualified individuals are listed. 
			`,
		},
		{
			title:
				'Will I need to complete another fingerprint-based background check?',
			content: `
				You will not need to complete an additional finger-print based background check if you have already completed and passed a fingerprint-based background check prior to June 1, 2025. 
				<br />
				<br />
				Consultants with disqualifying event(s) occurring on or after September 1, 2025, will be reviewed under the updated Summary of Disqualifiers chart.
				<br />
				<br />
				Please visit <a href="/background/disqualifiers" target="_blank" rel="noopener noreferrer"><b>Background-Disqualifiers</b></a> for more information.
			`,
		},
		{
			title:
				'What happens if I do not meet the registration requirements but have contracts that extend past September 1, 2025?',
			content: `
				If you do not meet the registration requirements by August 31, 2025, you will be removed from the Texas School Safety and Security Registry. Texas Education Code 37.2091(b-1) requires school districts to confirm that a person is included in the Registry before the district may engage the person to provide school safety or security consulting services to the district. 
				<br />
				<br />
				Contracting parties should consult with their legal team to determine if the contract should reach the natural end or be terminated.
				<br />
				<br />
				If you are concerned you will not meet the requirements by September 1, 2025, <a href="mailto:txssc_registry@txstate.edu"><b>txssc_registry@txstate.edu</b></a> contact us in advance so we can provide further guidance.   
			`,
		},
		{
			title: 'What Disciplines are offered?',
			content: `
				There are two Disciplines - the <a href="/disciplines/audit" target='_blank' rel='noopener noreferrer'><b>Audit Discipline</b></a> and the <a href="/disciplines/eop" target='_blank' rel='noopener noreferrer'><b>Emergency Operations Plan (EOP) Discipline</b></a>.
			`,
		},
		{
			title: 'What is a Pathway?',
			content: `
				Any person providing school safety or security consulting services to independent school districts, open-enrollment charter schools, and junior colleges in Texas must meet standards set by the Texas School Safety Center (TxSSC). Applicants to the Texas School Safety and Security Consultant Registry (Registry) must provide information regarding their background, education, and experience that is relevant to their ability to provide knowledgeable and effective school safety or security consulting services.
				<br />
				<br />
				Registry Applicants provide documentation of their experience in the Audit Discipline, EOP Discipline or both Disciplines via the Credential Pathway or the Experience Pathway. 
				<br />
				<br />
				<b>Credential Pathway</b> - requires proof of pre-identified certifications and at least one year of demonstrated experience in Texas since 2019. 
				<br />
				<br />
				<b>Experience Pathway</b> - requires at least five years of demonstrated experience in Texas since 2019. 
				<br />
				<br />
				TxSSC staff will verify year(s) of experience by contacting the References provided by the Registry Applicant. 
				<br />
				<br />
				When the requirements for the selected Discipline(s) have been met, the Applicant will appear as a Consultant on the Registry.  
			`,
		},
		{
			title:
				'Can Consultants provide a combination of Credential(s) and Experience(s) to create their own Pathway?',
			content: `No.`,
		},
		{
			title: 'What is considered demonstrated experience?',
			content: `
			Demonstrated experience may be professional or volunteer, full-time or part-time, and performed in an educational setting which includes independent school district, open-enrollment charter schools, junior colleges, universities, private schools, or licensed center-based childcare facilities (excluding home-based facilities) in Texas. All experience must be verified through References.
			`,
		},
		{
			title: 'How is experience calculated? ',
			content: `
			Experience is calculated on the duration of completed contracts. Contracts are counted separately and can be concurrent. For example, six separate 3-month contracts equate to 18 months of experience. 
			<br />
			<br />
			Contract completion and duration will be verified through References provided by the Applicant. Please visit <a href="/about/References" target="_blank" rel="noopener noreferrer"><b>About-References</b></a> for more information.
			`,
		},
		{
			title:
				'Why does experience prior to 2019 not count towards the requirement?',
			content: `
				Experience is only considered from September 1, 2019, to the present because this marks the enactment of Senate Bill 11 during the 86th Texas Legislative Session. This requirement ensures that all demonstrated experience aligns with the standards and specifications established by the law. 
			`,
		},
		{
			title: 'Why are Reference(s) required?',
			content: `
			Reference(s) are required to verify the accuracy of a Consultant's reported experience. Reference(s) ensure that the professional services provided were performed in an educational setting which includes independent school district, open-enrollment charter schools, junior colleges, universities, private schools, or licensed center-based childcare facilities (excluding home-based facilities) in Texas. The verification process helps maintain the integrity of the Registry and ensures that only qualified individuals are listed.
			`,
		},
		{
			title: 'Who qualifies to serve as a Reference? ',
			content: `
			A qualified Reference must be a current employee of the district or campus where the consulting services were provided. This individual should have been responsible for overseeing the fulfillment of the contract and can verify the Consultant's work. If the original point of contact is no longer employed, another current employee from the same agency may serve as the Reference so long as they are qualified to speak on the contract and the specifics of the services provided. 
			<br />
			<br />
			Consultants who have worked with multiple districts must provide a Reference from each district where services were rendered.
			`,
		},
		{
			title: 'What are Reference(s) asked?',
			content: `
			The Reference will confirm that the consulting services were rendered, the duration of contract(s), adherence to the timeline, fulfillment of agreed services, and whether the Consultant is eligible for rehire. 
			<br />
			<br />
			Please visit <a href="/about/References" target="_blank" rel="noopener noreferrer"><b>About-References</b></a> for more information.
			`,
		},
		{
			title:
				'Why is School Behavioral Threat Assessment (SBTA) no longer offered as a Discipline?',
			content: `
			The Texas School Safety Center (TxSSC) recognizes that threat assessment teams have ample access to evidence-based threat assessment training, implementation, documentation, data reporting, and file storage support and resources through aligned collaboration between the TxSSC, the Texas Education Agency’s (TEA) Office of School Safety and Security, and all 20 regional Education Service Centers.
			<br />
			<br />
			Threat assessment teams at independent school districts and open-enrollment charter schools* are mandated by Texas Education Code (TEC) 37.115(c)(2) to receive evidence-based threat assessment training from the TxSSC or regional educational service centers. The TxSSC also offers tiered training and consultation beyond the basic mandated SBTA training, including:
			<br />
			<br />
			<ul>
				<li>
					Intermediate SBTA
				</li>
				<li>
					Advanced SBTA
				</li>
				<li>
				 Operationalizing SBTA
				</li>
				<li>
					Digital Threat Assessment
				</li>
				<li>
					Advanced Threat Assessment
				</li>
			</ul>
			<br />
			Also, in accordance with TEC 37.1083, independent school districts and open-enrollment charter schools are required to submit school behavioral threat assessment documentation to TEA’s statewide Sentinel software. Sentinel creates a one-stop-shop for districts to collaborate with Office of School Safety and Security sector agents for additional support and resources.
			<br />
			<br />
			Furthermore, the TxSSC takes the stance that Registry Consultants should not participate in threat assessments and have access to student information that is protected by the Family Educational Rights and Privacy Act (FERPA).
			<br />
			<br />
			*Junior colleges are not mandated by Texas Education Code to participate in school behavioral threat assessment.
			`,
		},
		{
			title: "Why is 'Other' no longer offered as a Discipline?",
			content: `
			By narrowing the focus to the Audit and Emergency Operations Plan Disciplines, the Texas School Safety Center can ensure that all registered Consultants continue to provide knowledgeable and effective school safety or security consulting services.
			`,
		},
	];
	return (
		<div className='faqs'>
			<Masthead
				heading1='FAQs'
				heading2='2025 Registration for Active Consultants'
			/>

			<ol className='expander'>
				{faqSections.map((section, index) => (
					<li key={index} className='toc-section'>
						<div className='toc-header' onClick={() => toggleSection(index)}>
							<h3>{section.title}</h3>
							<i
								className={`fa fa-chevron-${
									expandedSections[index] ? 'down' : 'up'
								}`}></i>
						</div>
						{expandedSections[index] && (
							<div
								className='expandable'
								dangerouslySetInnerHTML={{ __html: section.content }}></div>
						)}
					</li>
				))}
			</ol>
			<div className='tool-tip'>
				<p className='tip-text'>
					<i className='fa fa-arrow-up'></i> Click on the section title for more
					info.
				</p>
			</div>
		</div>
	);
};

export default Fy25Renewal;
