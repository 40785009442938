import './App.scss';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// Components
import Footer from './components/Footer';
import Navigation from './components/Navigation';

// Home Page
import Home from './pages/Home';

// About
import RegistryOverview from './pages/About/RegistrtyOverview';
import DistrictOverview from './pages/About/DistrictOverview';
import Legislation from './pages/About/Legislation';
// import ConsultantConduct from './pages/About/ConsultantConduct';
import Purpose from './pages/About/Purpose';
import Policies from './pages/About/Policies';
import References from './pages/About/References';

// Background
import Appeal from './pages/Background/Appeal';
import Process from './pages/Background/Process';
import Disqualifiers from './pages/Background/Disqualifiers';

// Contact
import Complaint from './pages/Contact/Complaint';
// import Schedule from './pages/Contact/Schedule';
import Update from './pages/Contact/Update';
import Info from './pages/Contact/Info';

// Disciplines
import EOP from './pages/Disciplines/EOP';
import Audit from './pages/Disciplines/Audit';

// FAQs
import General from './pages/Faqs/General';
import Schools from './pages/Faqs/Schools';
import Background from './pages/Faqs/Background';
// import Renewal from './pages/Faqs/Renewal';
import Fy25Renewal from './pages/Faqs/Fy25Renewal';

// Logins
import RegistrationProcess from './pages/Login/RegistrationProcess';

// Dashboard
import Dashboard from './pages/Dashboard/Dashboard';

import Search from './pages/Search/Search';

export default function App() {
	// determine if qual or not https://registry.txssc.qual.txstate.edu/
	const qual = window.location.hostname === 'registry.txssc.qual.txstate.edu';
	const dev = window.location.hostname === 'localhost';
	return (
		<Router>
			<div id='wrap'>
				{qual ? (
					<div className='env-banner qual'>Quality Assurance Environment</div>
				) : null}
				{dev ? <div className='env-banner dev'>Development</div> : null}
				<div id='nav'>
					<Navigation />
				</div>
				<main className='content'>
					<Switch>
						<Route path='/about/purpose' component={Purpose} />
						<Route
							path='/about/registry-overview'
							component={RegistryOverview}
						/>
						<Route
							path='/about/district-overview'
							component={DistrictOverview}
						/>
						<Route path='/about/legislation' component={Legislation} />
						{/* <Route
							path='/about/consultant-conduct'
							component={ConsultantConduct}
						/> */}
						<Route path='/about/policies' component={Policies} />
						<Route path='/about/references' component={References} />

						<Route path='/disciplines/audit' component={Audit} />
						<Route path='/disciplines/eop' component={EOP} />

						<Route path='/background/process' component={Process} />
						<Route path='/background/appeal' component={Appeal} />
						<Route path='/background/disqualifiers' component={Disqualifiers} />

						<Route path='/faqs/general' component={General} />
						<Route path='/faqs/background' component={Background} />
						<Route path='/faqs/sd-charter-jc' component={Schools} />
						{/* <Route path='/faqs/renewal' component={Renewal} /> */}
						<Route path='/faqs/2025' component={Fy25Renewal} />

						<Route path='/contact/complaint' component={Complaint} />
						{/* <Route path='/contact/schedule' component={Schedule} /> */}
						<Route path='/contact-info' component={Info} />
						<Route path='/contact/update' component={Update} />


						<Route path='/search' component={Search} />

						<Route
							path='/registration-process'
							component={RegistrationProcess}
						/>

						<Route path='/dashboard' component={Dashboard} />

						<Route path='/' exact component={Home} />

						<Route path='*' component={Home} />
					</Switch>
				</main>
			</div>

			<div id='footer'>
				<Footer />
			</div>
		</Router>
	);
}
