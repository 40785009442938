import React from 'react';
import Masthead from '../../components/Masthead';
import useExpander from '../../hooks/useExpander';

const Schools = () => {
	const [expandedSections, toggleSection] = useExpander();

	const faqSections = [
		{
			title:
				'Who is required to register for the Texas School Safety and Security Consultant Registry?',
			content: `
			Any person providing school safety or security consulting services in Texas is required to be on the Registry. School safety or security consulting services includes any service provided to a school district, institution of higher education, district facility, or campus by a person consisting of advice, information, recommendations, data collection, or safety and security audit services relevant to school safety and security, regardless of whether the person is paid for those services.
			<br />
			<br />
			However, the following professionals are not required to be on the Registry as their industry receives oversight or regulation by a separate state agency. This list is not exhaustive. <a href="mailto:txssc_registry@txstate.edu"><b>Contact us</b></a> for additional guidance. 
			<br />
			<br />

			<ul>
				<li>
					<b>District Employees</b> carrying out their assigned job duties for their
					employing district.
				</li>
				<li>
					<b>Education Service Center (ESC) Specialists</b> providing Audit and/or EOP
					services to districts within their region do not need to be on the
					Registry; however, an individual that is contracted by the ESC to
					provide Audit and/or EOP services to districts within the ESC's region
					must be on the Registry as a Consultant.
				</li>
				<li>
					<b>School-based Law Enforcement</b>, <b>Marshals</b>, and <b>Guardians</b> carrying out
					their assigned job duties for their employing district.
				</li>
				<li>
					<b>School Safety Instructors Certified by DPS</b> teaching course
					material to employees of a school district or an open-enrollment
					charter school who hold a license to carry a handgun, however, if the
					contract includes Audit and/or EOP services then they must be on the
					Registry as a Consultant.
				</li>
				<li>
					<b>Private Security Guards</b> carrying out their assigned job duties for
					their contracting district; however, if the contract includes Audit
					and/or EOP services then they must be on the Registry as a Consultant.
				</li>
				<li>
					<b>Architects</b> may provide architectural services that impact safety and
					security; however, if the contract includes Audit and/or EOP services
					then they must be on the Registry as a Consultant.
				</li>
				<li>
					<b>First Responders</b> who provide Audit and/or EOP services to districts
					within their agency's jurisdiction.
				</li>
			</ul>
			`,
		},
		{
			title:
				'Does the Texas School Safety Center recommend or endorse Consultants on the Registry?',
			content: `
			The TxSSC does not recommend or endorse any Consultants on the Registry. The Registry is designed to connect independent school districts, open-enrollment charter schools, and junior colleges with registered Consultants who have been vetted to provide school safety or security consulting services in Texas. The district has sole discretion on who they contract with from the Registry for Audit and EOP services.
			`,
		},
		{
			title:
				"How do we know we are selecting the best Consultant for our district's needs?",
			content: `
			It is the responsibility of the hiring district to ensure the Consultant can perform the work needed by the district to the standards required by the district. Districts are responsible for evaluating performance, products, insurance documentation, and checking references.
			`,
		},
		{
			title: 'What is a Discipline?',
			content: `
				Any person providing school safety or security consulting services to independent school districts, open-enrollment charter schools, and junior colleges in Texas must meet standards set by the Texas School Safety Center (TxSSC). The TxSSC has identified training requirements for providing Audit or Emergency Operations Plan (EOP) consulting services, known as Disciplines.   
				<br />
				<br />
				Applicants are eligible to apply to the Audit Discipline and/or EOP Discipline via the Experience Pathway or Credential Pathway. The training requirements are specific to the Discipline being sought and documentation must be provided to show the Applicant has met all requirements. Once the training requirements for selected Discipline(s) have been met, the Applicant is now a registered Consultant appearing on the Texas School Safety and Security Consultant Registry.
      `,
		},
		{
			title:
				'Where can I find more information on the requirements for the Audit Discipline and EOP Discipline?',
			content: `
				Please visit <a href="/disciplines/audit" target='_blank' rel='noopener noreferrer'><b>Disciplines-Audit</b></a> or <a href="/disciplines/eop" target='_blank' rel='noopener noreferrer'><b>Disciplines-EOP</b></a> for more information.
			`,
		},
		{
			title: 'What is a Pathway?',
			content: `
				Any person providing school safety or security consulting services to independent school districts, open-enrollment charter schools, and junior colleges in Texas must meet standards set by the Texas School Safety Center (TxSSC). Applicants to the Texas School Safety and Security Consultant Registry (Registry) must provide information regarding their background, education, and experience that is relevant to their ability to provide knowledgeable and effective school safety or security consulting services.
				<br />
				<br />
				Registry Applicants provide documentation of their experience in the Audit Discipline, EOP Discipline or both Disciplines via the Credential Pathway or the Experience Pathway. 
				<br />
				<br />
				<b>Credential Pathway</b> - requires proof of pre-identified certifications and at least one year of demonstrated experience in Texas since 2019. 
				<br />
				<br />
				<b>Experience Pathway</b> - requires at least five years of demonstrated experience in Texas since 2019. 
				<br />
				<br />
				TxSSC staff will verify year(s) of experience by contacting the references provided by the Registry Applicant. 
				<br />
				<br />
				When the requirements for the selected Discipline(s) have been met, the Applicant will appear as a Consultant on the Registry.  
			`,
		},
		{
			title:
				"How will the TxSSC verify complaints or pending litigation relating to the person's provision of school safety or security consulting services?",
			content: `
				The Texas School Safety Center (TxSSC) utilizes the Texas Appeals Management and eFiling System (TAMES) and Public Access to Court Electronic Records (PACER). 
				<br />
				<br />
				The TxSSC may use discretion to disqualify an Applicant or Consultant from the Registry for any civil or criminal litigation involving business practices that may impede the ability to provide contracting services to school districts. Applicants and Consultants are entitled to appeal a denial decision made by the TxSSC.
				<br />
				<br />
				Please visit <a href="/background/process" target='_blank' rel='noopener noreferrer'><b>Background-Check Process</b></a> for more information.
			`,
		},
		{
			title: 'What is the protocol if a Consultant gets arrested?',
			content: `
			The Texas School Safety Center (TxSSC) receives notifications from the Texas Department of Public Safety when an Applicant or Consultant has an update to their fingerprint-based criminal history. TxSSC staff will review the Summary of Disqualifiers to determine if an Applicant or Consultant is ineligible for inclusion on the Registry. If it is an offense that would disqualify them from being included on the Registry, they will be removed with an opportunity for appeal. Please visit <a href='/background/disqualifiers'><b>Background-Disqualifiers</b></a> for more information.
			`,
		},
		{
			title:
				'What kind of attributes will be available when I search for a Consultant?',
			content: `
				Each Consultant's profile displays their veteran status, Historically  Underutilized Business (HUB) status, and Centralized Master Bidders List (CMBL) vendor status as applicable. Additionally, search results can be filtered by services provided (Disciplines) and ESC region served.
			`,
		},
		{
			title: 'How do I file a complaint regarding a Consultant?',
			content: `
      If there is reason to believe a Consultant should not be included on the Registry for a disqualifying reason, please review <a href="/about/Consultant-conduct" target='_blank' rel='noopener noreferrer'><b>About-Consultant Conduct</b></a> and <a href="/background/disqualifiers" target='_blank' rel='noopener noreferrer'><b>Background-Disqualifiers</b></a> prior to reporting a complaint. 
			<br />
			<br />
			Please visit <a href="/contact/complaints" target='_blank' rel='noopener noreferrer'><b>Contact Us-File a Complaint</b></a> for more information.
      `,
		},
	];

	return (
		<div className='faqs'>
			<Masthead
				heading1='FAQs'
				heading2='For School Districts, Charters, and Junior Colleges'
			/>

			<ol className='expander'>
				{faqSections.map((section, index) => (
					<li key={index} className='toc-section'>
						<div className='toc-header' onClick={() => toggleSection(index)}>
							<h3>{section.title}</h3>
							<i
								className={`fa fa-chevron-${
									expandedSections[index] ? 'down' : 'up'
								}`}></i>
						</div>
						{expandedSections[index] && (
							<div
								className='expandable'
								dangerouslySetInnerHTML={{ __html: section.content }}></div>
						)}
					</li>
				))}
			</ol>
			<div className='tool-tip'>
				<p className='tip-text'>
					<i className='fa fa-arrow-up'></i> Click on the section title for more
					info.
				</p>
			</div>
		</div>
	);
};

export default Schools;
