import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
	return (
		<Fragment>
			<div className='centered'>
				<h1>
					Welcome to the Texas School Safety and Security Consultant Registry
				</h1>
			</div>
			<p className='left-full'>
				The Registry is mandated by{' '}
				<Link to='/about/legislation'>
					<b>Texas Education Code 37.2091</b>
				</Link>{' '}
				and consists of registered Consultants who have been vetted to provide
				school safety or security consulting services to independent school
				districts, open-enrollment charter schools, and junior colleges in
				Texas.
			</p>
			<div className='callout emphasized'>
				<p className='left-full'>
					<b>
						The Registry will be closed for maintenance and policy updates
						beginning March 21, 2025, and will reopen on June 1, 2025.
						Registration for active Consultants and new Applicants will resume
						on June 1, 2025, under the updated registration requirements for
						inclusion on the Registry.
					</b>
				</p>
			</div>
			<div className='home-page'>
				<Link to='/search'>
					<div className='find-consultant'>
						{/* <div className='coming-soon'>Now Open!</div> */}
						<i id='info' className='fa fa-search' aria-hidden='true'></i>
						<h2>Find a Consultant on the Registry</h2>
						<p>Need Consultant services?</p>
						Search the Registry of vetted Consultants.
					</div>
				</Link>

				<Link to='/registration-process'>
					<div className='apply-consultant'>
						<div className='corner-banner'>Reopens 6/1/25</div>
						<i id='laptop' className='fa fa-laptop' aria-hidden='true'></i>
						<h2>Apply to Become a Consultant</h2>
						<p>&#8203;</p>
						&#8203;
					</div>
				</Link>
			</div>
		</Fragment>
	);
};

export default Home;
