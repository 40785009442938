import React from 'react';
import Masthead from '../../components/Masthead';

const Purpose = () => {
	return (
		<div className='about'>
			<Masthead heading1='About' heading2='Purpose' />

			<p>
				<span style={{ fontWeight: '600' }}>Purpose</span> -The Texas School
				Safety and Security Consultant Registry (Registry) exists to fulfill the
				requirements of{' '}
				<a
					href='https://statutes.capitol.texas.gov/Docs/ED/htm/ED.37.htm#37.2091'
					target='_blank'
					rel='noopener noreferrer'>
					<strong>Texas Education Code 37.2091</strong>
				</a>
				. The Registry consists of registered Consultants who have been vetted
				to provide school safety or security consulting services in Texas.
			</p>

			<p>
				School safety or security consulting services includes any service
				provided to a school district, institution of higher education, district
				facility, or campus by a person consisting of advice, information,
				recommendations, data collection, or safety and security audit services
				relevant to school safety and security, regardless of whether the person
				is paid for those services.
			</p>

			<p>
				The following professionals are <b>NOT</b> required to be listed on the
				Registry, as their industry receives oversight or regulation by a
				separate state agency.
			</p>
			<p>
				This list is not exhaustive. Please reach out to{' '}
				<a href='mailto:txssc_registry@txstate.edu'>
					<b>txssc_registry@txstate.edu</b>
				</a>{' '}
				for additional guidance.
			</p>
			<ul>
				<li>
					<b>District Employees</b> carrying out their assigned job duties for
					their employing district.
				</li>
				<li>
					<b>Education Service Center (ESC) Specialists</b> providing Audit
					and/or EOP services to districts within their region do not need to be
					on the Registry; however, an individual that is contracted by the ESC
					to provide Audit and/or EOP services to districts within the ESC's
					region must be on the Registry as a Consultant.
				</li>
				<li>
					<b>School-Based Law Enforcement, Marshals</b>, and <b>Guardians</b>{' '}
					carrying out their assigned job duties for their employing district.
				</li>
				<li>
					<b>School Safety Instructors Certified by DPS</b> teaching course
					material to employees of a school district or an open-enrollment
					charter school who hold a license to carry a handgun, however, if the
					contract includes Audit and/or EOP services then they must be on the
					Registry as a Consultant.
				</li>
				<li>
					<b>Private Security Guards </b>carrying out their assigned job duties
					for their contracting district; however, if the contract includes
					Audit and/or EOP services then they must be on the Registry as a
					Consultant.
				</li>
				<li>
					<b>Architects</b> may provide architectural services that impact
					safety and security; however, if the contract includes Audit and/or
					EOP services then they must be on the Registry as a Consultant.
				</li>
				<li>
					<b>First Responders</b> who provide Audit and/or EOP services to
					districts within their agency's jurisdiction.
				</li>
			</ul>
		</div>
	);
};

export default Purpose;
