import React from 'react';
import Masthead from '../../components/Masthead';

const Disqualifiers = () => {
	return (
		<div className='background'>
			<Masthead heading1='Background' heading2='Disqualifiers' />
			<p>
				Inclusion on the Texas School Safety and Security Consultant Registry
				(Registry) is contingent on the results of a background check.
			</p>

			<p>The background check consists of the following:</p>
			<ul>
				<li>
					Fingerprint-based criminal history review (at Applicant's expense)
				</li>
				<li>
					<a
						href='https://www.nsopw.gov/'
						target='_blank'
						rel='noopener noreferrer'>
						National Sex Offender Public Website
					</a>{' '}
					review
				</li>
				<li>
					<a
						href='https://tealprod.tea.state.tx.us/DNH/Public/SearchPerson'
						target='_blank'
						rel='noopener noreferrer'>
						Texas Education Agency Do Not Hire Registry
					</a>{' '}
					review
				</li>
				<li>
					<a
						href='https://tealprod.tea.state.tx.us/ECOS-External/EcosOnline/VirtCert'
						target='_blank'
						rel='noopener noreferrer'>
						State Board for Educator Certification
					</a>{' '}
					review
				</li>
				<li>
					Litigation review via the Texas Appeals Management and eFiling System
					(
					<a
						href='https://search.txcourts.gov/CaseSearch.aspx?coa=cossup'
						target='_blank'
						rel='noopener noreferrer'>
						TAMES
					</a>
					) and Public Access to Court Electronic Records (
					<a
						href='https://pacer.uscourts.gov/'
						target='_blank'
						rel='noopener noreferrer'>
						PACER
					</a>
					)
				</li>
			</ul>

			<p>
				The Texas School Safety Center (TxSSC) reserves the right to use
				discretion and refuse, suspend, or revoke Registry status based on
				disqualifying event(s) identified during the initial background check
				review or at any time as an actively registered Consultant.
			</p>

			<p>
				When a disqualifying event results in a denial or removal from the
				Registry, the TxSSC has an established Appeal Process that will be
				provided to the Applicant or Consultant in writing at the time of
				denial.
			</p>

			<p>
				Deferred adjudications, convictions for similar crimes in states other
				than Texas, and any civil or criminal litigation involving business
				practices that may impede the ability to provide contracting services to
				school districts are considered disqualifiers.
			</p>

			<p style={{ marginBottom: '20px' }}>
				The Summary of Disqualifiers is a reference intended to provide guidance
				and is not exhaustive.
			</p>

			<h3 class='centered lg-text' id='appendix-1'>
				Summary of Disqualifiers
			</h3>
			<h3 class='centered'>Non-Criminal History</h3>
			<table class='appendix'>
				<thead>
					<tr>
						<th>Non-Criminal History</th>
						<th>Penalty</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>National Sex Offender Public Website</td>
						<td>Automatic Disqualifier</td>
					</tr>
					<tr>
						<td>Texas Education Agency (TEA) Do Not Hire Registry</td>
						<td>Automatic Disqualifier</td>
					</tr>
					<tr>
						<td>State Board for Educator Certification</td>
						<td>Additional Review Required</td>
					</tr>
					<tr>
						<td>Litigation (past, present, pending)</td>
						<td>Additional Review Required</td>
					</tr>
				</tbody>
			</table>
			<h3 class='centered'>Texas Penal Code</h3>
			<table class='appendix'>
				<thead>
					<tr>
						<th>Texas Penal Code</th>
						<th>Offense</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>CHAPTER 15</td>
						<td>PREPARATORY OFFENSES</td>
					</tr>
					<tr>
						<td>CHAPTER 19</td>
						<td>CRIMINAL HOMICIDE</td>
					</tr>
					<tr>
						<td>CHAPTER 20</td>
						<td>KIDNAPPING, UNLAWFUL RESTRAINT, AND SMUGGLING OF PERSONS</td>
					</tr>
					<tr>
						<td>CHAPTER 20A</td>
						<td>TRAFFICKING OF PERSONS</td>
					</tr>
					<tr>
						<td>CHAPTER 21</td>
						<td>SEXUAL OFFENSES</td>
					</tr>
					<tr>
						<td>CHAPTER 22</td>
						<td>ASSAULTIVE OFFENSES</td>
					</tr>
					<tr>
						<td>CHAPTER 25</td>
						<td>OFFENSES AGAINST THE FAMILY</td>
					</tr>
					<tr>
						<td>CHAPTER 28.02</td>
						<td>ARSON</td>
					</tr>
					<tr>
						<td>CHAPTER 28.04</td>
						<td>RECKLESS DAMAGE OR DESTRUCTION</td>
					</tr>
					<tr>
						<td>CHAPTER 29</td>
						<td>ROBBERY</td>
					</tr>
					<tr>
						<td>CHAPTER 30</td>
						<td>BURGLARY AND CRIMINAL TRESPASS</td>
					</tr>
					<tr>
						<td>CHAPTER 31</td>
						<td>THEFT</td>
					</tr>
					<tr>
						<td>CHAPTER 32</td>
						<td>FRAUD</td>
					</tr>
					<tr>
						<td>CHAPTER 33.021</td>
						<td>ONLINE SOLICITATION OF A MINOR</td>
					</tr>
					<tr>
						<td>CHAPTER 33.07</td>
						<td>ONLINE IMPERSONATION</td>
					</tr>
					<tr>
						<td>CHAPTER 34</td>
						<td>MONEY LAUNDERING</td>
					</tr>
					<tr>
						<td>CHAPTER 35</td>
						<td>INSURANCE FRAUD</td>
					</tr>
					<tr>
						<td>CHAPTER 35A</td>
						<td>HEALTHCARE FRAUD</td>
					</tr>
					<tr>
						<td>CHAPTER 36</td>
						<td>BRIBERY AND CORRUPT INFLUENCE</td>
					</tr>
					<tr>
						<td>CHAPTER 37</td>
						<td>PERJURY AND OTHER FALSIFICATION</td>
					</tr>
					<tr>
						<td>CHAPTER 38.17</td>
						<td>
							FAILURE TO STOP OR REPORT AGGRAVATED SEXUAL ASSAULT OF CHILD
						</td>
					</tr>
					<tr>
						<td>CHAPTER 38.172</td>
						<td>
							FAILURE TO REPORT ASSAULT, NEGLECT, OR OMISSION OF CARE IN CERTAIN
							GROUP HOMES
						</td>
					</tr>
					<tr>
						<td>CHAPTER 39</td>
						<td>ABUSE OF OFFICE</td>
					</tr>
					<tr>
						<td>CHAPTER 42.07</td>
						<td>HARASSMENT</td>
					</tr>
					<tr>
						<td>CHAPTER 42.072</td>
						<td>STALKING</td>
					</tr>
					<tr>
						<td>CHAPTER 43</td>
						<td>PUBLIC INDECENCY</td>
					</tr>
					<tr>
						<td>CHAPTER 46</td>
						<td>WEAPONS</td>
					</tr>
					<tr>
						<td>CHAPTER 49</td>
						<td>INTOXICATION AND ALCOHOLIC BEVERAGE OFFENSES</td>
					</tr>
					<tr>
						<td>CHAPTER 71.022</td>
						<td>
							COERCING, INDUCING, OR SOLICITING MEMBERSHIP IN A CRIMINAL STREET
							GANG OR FOREIGN TERRORIST ORGANIZATION
						</td>
					</tr>
					<tr>
						<td>CHAPTER 76</td>
						<td>TERRORISTIC OFFENSES</td>
					</tr>
				</tbody>
			</table>
			<h3 class='centered'>Texas Controlled Substances Act</h3>
			<table class='appendix'>
				<thead>
					<tr>
						<th>Texas Controlled Substances Act</th>
						<th>Subchapter</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>CHAPTER 481</td>
						<td>SUBCHAPTER D. OFFENSES AND PENALTIES</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
};

export default Disqualifiers;
