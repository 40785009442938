import React from 'react';
import Masthead from '../../components/Masthead';

const Policies = () => {
	return (
		<div className='about'>
			<Masthead heading1='About' heading2='Policies and Procedures' />

			<h2>General Conduct</h2>

			<p className='left-full'>
				Consultants shall abide by all legislative mandates and requirements set
				forth by the Texas School Safety Center (TxSSC). Consultants may be
				removed from Texas School Safety and Security Consultant Registry
				(Registry) for a violation of a legislative mandate or TxSSC policies
				and procedures, including, but not limited to, the following areas
				referenced below.
			</p>

			<h2>Misrepresentation</h2>

			<p className='left-full'>
				Consultants shall not claim affiliation with or endorsement by the
				TxSSC. Consultants shall not display or advertise the TxSSC name, logo,
				or likeness. Misrepresentation does not preclude a registered Consultant
				from advertising their status, including Disciplines, on the Registry.
			</p>

			<h2>Disciplines</h2>

			<p className='left-full'>
				Consultants must satisfy the requirements for each Discipline in which
				they perform services. Consultants performing work outside of their
				registered Discipline(s) are subject to suspension or revocation from
				the Registry.
			</p>

			<h2>Contact Information</h2>

			<p className='left-full'>
				During the Registration Process, and as part of ongoing communication,
				the TxSSC will send emails that may identify the next steps in the
				registration process, corrections necessary to continue the registration
				process or to maintain active status as a registered Consultant. It is
				the Consultant's and Applicant's responsibility to ensure that the TxSSC
				has current contact information. Failure to respond to a request from
				the TxSSC after 30 calendar days will require the Applicant or
				Consultant to restart the Registration Process.
			</p>

			<h2>Company Registration Prohibited</h2>

			<p className='left-full'>
				Companies or organizations cannot apply for inclusion on the Registry.
			</p>

			<p className='left-full'>
				The Registry is comprised of persons providing school safety or security
				consulting services in Texas. Persons providing school safety or
				security consulting services in Texas are required by{' '}
				<a
					href='https://statutes.capitol.texas.gov/Docs/ED/htm/ED.37.htm#37.2091'
					target='_blank'
					rel='noopener noreferrer'>
					<b>Texas Education Code 37.2091</b>
				</a>{' '}
				to register with the TxSSC.
			</p>

			<p className='left-full'>
				Each person performing school safety or security consulting services,
				whether on behalf of themselves, a company, or an organization must be a
				registered Consultant regardless of whether the person is paid for those
				services.
			</p>

			<h2>Policies and Procedures</h2>

			<p className='left-full'>
				The TxSSC reserves the right to revise and update Registry policies and
				procedures as necessary. For more information, refer to the Policies and
				Procedures Manual.
			</p>

			<p className='cta-button inactive left-align'>
				Policy and Procedure Manual Coming Soon
			</p>
		</div>
	);
};

export default Policies;
