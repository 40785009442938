import React from 'react';
import Masthead from '../../components/Masthead';

const Complaint = () => {
	return (
		<div className='contact'>
			<Masthead heading1='Contact Us' heading2='File a Complaint' />
			<p>
				Please review the{' '}
				<a
					href='/about/consultant-conduct'
					target='_blank'
					rel='noopener noreferrer'>
					<b>About-Consultant Conduct</b>
				</a>{' '}
				page and the Summary of Disqualifiers found on the{' '}
				<a
					href='/background/disqualifiers'
					target='_blank'
					rel='noopener noreferrer'>
					<b>Background-Disqualifiers</b>
				</a>{' '}
				page prior to reporting a complaint concerning an actively registered
				Consultant.
			</p>

			<p>When reporting a complaint, provide the following information:</p>
			<ul>
				<li>Complainant's contact information</li>
				<li>Respondent's contact information</li>
				<li>Pertinent details to support the complaint</li>
				<li>Alleged policy or procedure violation</li>
			</ul>

			<p>
				Texas School Safety Center staff will review the complaint accordingly.
			</p>

			<a
				href='https://forms.txssc.txstate.edu/registry/file-a-complaint/form'
				aria-label='File a Complaint Form'
				target='_blank'
				rel='noopener noreferrer'>
				<p className='cta-button left-align'>File a Complaint</p>
			</a>
		</div>
	);
};

export default Complaint;
