import React from 'react';
import Masthead from '../../components/Masthead';

const RegistrationProcess = () => {
	return (
		<div className='disciplines'>
			<Masthead heading1='About' heading2='Registration Process' />

			<div className='callout emphasized'>
				<p className='left-full'>
					<b>
						The Registry will be closed for maintenance and policy updates
						beginning March 21, 2025, and will reopen on June 1, 2025.
						Registration for active Consultants and new Applicants will resume
						on June 1, 2025, under the updated registration requirements for
						inclusion on the Registry.
					</b>
				</p>
			</div>

			<p className='left-full'>
				Every person providing school safety or security consulting services in
				the state of Texas is required by{' '}
				<a
					href='https://statutes.capitol.texas.gov/Docs/ED/htm/ED.37.htm#37.2091'
					target='_blank'
					rel='noopener noreferrer'>
					<b>TEC 37.2091</b>
				</a>{' '}
				to register with the Texas School Safety Center (TxSSC) in accordance
				with requirements established by the TxSSC.
			</p>

			<p className='left-full'>
				The Registration Process consists of four parts:{' '}
				<b>
					General Application, Background Check, Discipline Application, and
					Reference Verification.
				</b>
			</p>

			<p className='left-full'>
				During the Registration Process, and as part of ongoing communication,
				you will receive emails from the TxSSC. Emails may identify the next
				steps in the Registration Process, corrections necessary to continue the
				Registration Process or to maintain active status as a registered
				Consultant. Failure to respond to a request from the TxSSC after 30
				calendar days will require the Applicant to restart the Registration
				Process. Please ensure emails from the TxSSC are not classified as spam.
			</p>

			<div className='banner-list row lighten'>
				<h3 className='header spanning'>
					<small>General Application </small>
				</h3>

				<p style={{ margin: '20px 0px 0px 10px' }}>
					All Applicants to the Texas School Safety and Security Consultant
					Registry (Registry) must complete the General Application.
				</p>

				<ul className='announcement-font standard no-margin-top'>
					<li>
						Provide the following information:
						<ul class='no-margin-top'>
							<li>Personal Information</li>
							<li>Contact Information</li>
							<li>Organization/Company Affiliation</li>
							<li>Centralized Master Bidders List (CMBL) vendor ID*</li>
							<li>Historically Underutilized Business (HUB) vendor ID*</li>
							<li>
								Certificate of Release or Discharge from Active Duty excluding
								dishonorable conditions*
							</li>
							<li>Details of any civil or criminal litigation*</li>
						</ul>
					</li>
					<li>Complete the Application Acknowledgement</li>
					<li>Complete the Fingerprint Check Acknowledgement</li>
					<li>
						Ensure all fields in the General Application are completed as
						progress cannot be saved if you are unable to complete in a single
						session
					</li>
					<li>Submit</li>
				</ul>

				<p style={{ margin: '20px 0px 0px 10px' }}>* if applicable</p>

				<p className='cta-button inactive'>Step-by-Step Guide coming soon.</p>

				{/* <p style={{ margin: '20px 0px 0px 10px' }}>
					For detailed instructions, view the{' '}
					<a
						href='https://locker.txssc.txstate.edu/108631ffbe5a7d437a117717af9d3bc1/Step-by-Step-Guide-General-Application.pdf'
						target='_blank'
						rel='noopener noreferrer'>
						<b>General Application Step-by-Step Guide</b>
					</a>
				</p> */}
			</div>

			<div className='banner-list row lighten'>
				<h3 className='header spanning'>
					<small>Background Check</small>
				</h3>

				<p style={{ margin: '20px 0px 0px 10px' }}>
					All Applicants to the Registry must undergo a background check. The
					background check includes a fingerprint-based criminal history review
					(at Applicant's expense), National Sex Offender Public Website review,
					Texas Education Agency Do Not Hire Registry review, State Board for
					Educator Certification review, and litigation review.
				</p>

				<p style={{ margin: '20px 0px 0px 10px' }}>
					Upon review and approval of the General Application, the Applicant
					will receive the background check instructions and required service
					code unique to the TxSSC.
				</p>

				<p style={{ margin: '20px 0px 0px 10px' }}>
					The service code allows the TxSSC to review the results of an
					Applicant's fingerprint-based background check. TxSSC staff review the
					Summary of Disqualifiers to determine if an Applicant or Consultant is
					ineligible for inclusion on the Registry.
				</p>

				<p style={{ margin: '20px 0px 0px 10px' }}>
					Upon review, if an Applicant's background check does not exclude them
					from the Registry, the Applicant will receive the Discipline
					Application instructions via email.
				</p>

				<p style={{ margin: '20px 0px 0px 10px' }}>
					Please visit the{' '}
					<a href='/background/process'>
						<b>Background Check Process</b>
					</a>{' '}
					for more information.
				</p>
			</div>

			<div className='banner-list row lighten'>
				<h3 className='header spanning'>
					<small>Discipline Application </small>
				</h3>

				<p style={{ margin: '20px 0px 0px 10px' }}>
					Any person providing school safety or security consulting services to
					school districts, charter schools, or public junior colleges in Texas
					must meet standards set by the Texas School Safety Center (TxSSC). The
					TxSSC has identified basic training requirements for providing Audit
					or Emergency Operations Plan (EOP) consulting services, known as
					Disciplines.
				</p>

				<p style={{ margin: '20px 0px 0px 10px' }}>
					If you have not received an email approving your General Application,
					you cannot begin the Discipline Application.
				</p>

				<ul className='announcement-font standard no-margin-top'>
					<li>
						Open the <b>“Discipline Application”</b> instructions provided via
						email.
					</li>
					<li>
						Provide the following information:
						<ul>
							<li>General Application Confirmation Code</li>
							<li>Personal Information</li>
							<li>Public Contact Information</li>
							<li>
								Education Service Center (ESC) Region(s) you intend to serve
							</li>
							<li>
								Discipline specific requirements:
								<ul>
									<li>
										<a
											href='/disciplines/audit'
											target='_blank'
											rel='noopener noreferrer'>
											<b>Audit</b>
										</a>
									</li>
									<li>
										<a
											href='/disciplines/eop'
											target='_blank'
											rel='noopener noreferrer'>
											<b>EOP</b>
										</a>
									</li>
								</ul>
							</li>
						</ul>
					</li>
					<li>
						Ensure all fields in the Discipline Application are completed as
						progress cannot be saved if you are unable to complete in a single
						session
					</li>
					<li>Submit</li>
				</ul>

				<p style={{ margin: '20px 0px 0px 10px' }}>
					For Discipline specific requirements, please visit{' '}
					<a href='/disciplines/audit'>
						<b>Disciplines-Audit</b>
					</a>{' '}
					and{' '}
					<a href='/disciplines/eop'>
						<b>Discipline-Emergency Operations Plan (EOP)</b>
					</a>{' '}
					for more information.
				</p>

				<p className='cta-button inactive'>Step-by-Step Guide coming soon.</p>
			</div>

			<div className='banner-list row lighten'>
				<h3 className='header spanning'>
					<small>Reference Verification</small>
				</h3>

				<p style={{ margin: '20px 0px 0px 10px' }}>
					TxSSC staff will contact the References provided by the Applicant from
					their submitted Discipline Application.
				</p>

				<p style={{ margin: '20px 0px 0px 10px' }}>
					Reference(s) are required to verify the accuracy of a Consultant’s
					reported experience and will confirm that consulting services were
					rendered, the duration of contract(s), adherence to the timeline,
					fulfillment of agreed services, and whether the Consultant is eligible
					for rehire.
				</p>

				<p style={{ margin: '20px 0px 0px 10px' }}>
					Please ensure your Reference(s) knows that the TxSSC will be in
					contact. A lack of response will delay the completion of the
					Registration Process and your status as a registered Consultant.
				</p>

				<p style={{ margin: '20px 0px 0px 10px' }}>
					Please visit{' '}
					<a href='/about/references' target='_blank' rel='noopener noreferrer'>
						<b>About-References</b>
					</a>{' '}
					for more information.
				</p>
			</div>
		</div>
	);
};

export default RegistrationProcess;
