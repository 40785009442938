import React from 'react';
import Masthead from '../../components/Masthead';
import useExpander from '../../hooks/useExpander';

const General = () => {
	const [expandedSections, toggleSection] = useExpander();

	const faqSections = [
		{
			title:
				'Who is required to register for the Texas School Safety and Security Consultant Registry?',
			content: `
			Any person providing school safety or security consulting services in Texas is required to be on the Registry. School safety or security consulting services includes any service provided to a school district, institution of higher education, district facility, or campus by a person consisting of advice, information, recommendations, data collection, or safety and security audit services relevant to school safety and security, regardless of whether the person is paid for those services.
			<br />
			<br />
			However, the following professionals are not required to be on the Registry as their industry receives oversight or regulation by a separate state agency. This list is not exhaustive. <a href="mailto:txssc_registry@txstate.edu"><b>Contact us</b></a> for additional guidance. 
			<br />
			<br />

			<ul>
				<li>
					<b>District Employees</b> carrying out their assigned job duties for their
					employing district.
				</li>
				<li>
					<b>Education Service Center (ESC) Specialists</b> providing Audit and/or EOP
					services to districts within their region do not need to be on the
					Registry; however, an individual that is contracted by the ESC to
					provide Audit and/or EOP services to districts within the ESC's region
					must be on the Registry as a Consultant.
				</li>
				<li>
					<b>School-based Law Enforcement</b>, <b>Marshals</b>, and <b>Guardians</b> carrying out
					their assigned job duties for their employing district.
				</li>
				<li>
					<b>School Safety Instructors Certified by DPS</b> teaching course
					material to employees of a school district or an open-enrollment
					charter school who hold a license to carry a handgun, however, if the
					contract includes Audit and/or EOP services then they must be on the
					Registry as a Consultant.
				</li>
				<li>
					<b>Private Security Guards</b> carrying out their assigned job duties for
					their contracting district; however, if the contract includes Audit
					and/or EOP services then they must be on the Registry as a Consultant.
				</li>
				<li>
					<b>Architects</b> may provide architectural services that impact safety and
					security; however, if the contract includes Audit and/or EOP services
					then they must be on the Registry as a Consultant.
				</li>
				<li>
					<b>First Responders</b> who provide Audit and/or EOP services to districts
					within their agency's jurisdiction.
				</li>
			</ul>
			`,
		},
		{
			title: 'Can businesses register and include all employees?',
			content: `
          Companies or organizations cannot apply for inclusion on the Registry.
					<br />
					<br />
          Each person performing school safety or security consulting services, whether on behalf of themselves, a company, or an organization must become a Consultant on the Registry regardless of whether the person is paid for those services.
      `,
		},
		{
			title:
				'Does the Texas School Safety Center recommend or endorse Consultants on the Registry?',
			content: `
          The TxSSC does not recommend or endorse any Consultants on the Registry. The Registry is designed to connect independent school districts, open-enrollment charter schools, and junior colleges with registered Consultants who have been vetted to provide school safety or security consulting services in Texas. The district has sole discretion on who they contract with from the Registry for Audit and EOP services.
      `,
		},
		{
			title: 'What is a Discipline?',
			content: `
				Any person providing school safety or security consulting services to independent school districts, open-enrollment charter schools, and junior colleges in Texas must meet standards set by the Texas School Safety Center (TxSSC). The TxSSC has identified training requirements for providing Audit or Emergency Operations Plan (EOP) consulting services, known as Disciplines.   
				<br />
				<br />
				Applicants are eligible to apply to the <a href="/disciplines/audit" target='_blank' rel='noopener noreferrer'><b>Audit Discipline</b></a> and/or <a href="/disciplines/eop" target='_blank' rel='noopener noreferrer'><b>EOP Discipline</b></a> via the Experience Pathway or Credential Pathway. The training requirements are specific to the Discipline being sought and documentation must be provided to show the Applicant has met all requirements. Once the training requirements for selected Discipline(s) have been met, the Applicant is now a registered Consultant appearing on the Texas School Safety and Security Consultant Registry.
      `,
		},
		{
			title: 'What Disciplines are offered?',
			content: `
				There are two Disciplines - the <a href="/disciplines/audit" target='_blank' rel='noopener noreferrer'><b>Audit Discipline</b></a> and the <a href="/disciplines/eop" target='_blank' rel='noopener noreferrer'><b>Emergency Operations Plan (EOP) Discipline</b></a>.
			`,
		},
		{
			title: 'What is a Pathway?',
			content: `
				Any person providing school safety or security consulting services to independent school districts, open-enrollment charter schools, and junior colleges in Texas must meet standards set by the Texas School Safety Center (TxSSC). Applicants to the Texas School Safety and Security Consultant Registry (Registry) must provide information regarding their background, education, and experience that is relevant to their ability to provide knowledgeable and effective school safety or security consulting services.
				<br />
				<br />
				Applicants provide documentation of their experience in the Audit Discipline, EOP Discipline or both Disciplines via the Credential Pathway or the Experience Pathway. 
				<br />
				<br />
				<b>Credential Pathway</b> - requires proof of pre-identified certifications and at least one year of demonstrated experience in Texas since 2019. 
				<br />
				<br />
				<b>Experience Pathway</b> - requires at least five years of demonstrated experience in Texas since 2019. 
				<br />
				<br />
				TxSSC staff will verify year(s) of experience by contacting the References provided by the Registry Applicant. 
				<br />
				<br />
				When the requirements for the selected Discipline(s) have been met, the Applicant will appear as a Consultant on the Registry.  
			`,
		},
		{
			title:
				'Can Consultants provide a combination of Credential(s) and Experience(s) to create their own Pathway?',
			content: `No.`,
		},
		{
			title: 'What is considered demonstrated experience?',
			content: `
			Demonstrated experience may be professional or volunteer, full-time or part-time, and performed in an educational setting which includes independent school district, open-enrollment charter schools, junior colleges, universities, private schools, or licensed center-based childcare facilities (excluding home-based facilities) in Texas. All experience must be verified through References.
			`,
		},
		{
			title: 'How is experience calculated? ',
			content: `
			Experience is calculated on the duration of completed contracts. Contracts are counted separately and can be concurrent. For example, six separate 3-month contracts equate to 18 months of experience. 
			<br />
			<br />
			Contract completion and duration will be verified through References provided by the Applicant. Please visit <a href="/about/References" target="_blank" rel="noopener noreferrer"><b>About-References</b></a> for more information.
			`,
		},
		{
			title:
				'Why does experience prior to 2019 not count towards the requirement?',
			content: `
				Experience is only considered from September 1, 2019, to the present because this marks the enactment of Senate Bill 11 during the 86th Texas Legislative Session. This requirement ensures that all demonstrated experience aligns with the standards and specifications established by the law. 
			`,
		},
		{
			title: 'Why are Reference(s) required?',
			content: `
			Reference(s) are required to verify the accuracy of a Consultant’s reported experience. Reference(s) ensure that the professional services provided aligned with the required standards and were performed in an educational setting which includes independent school district, open-enrollment charter schools, junior colleges, universities, private schools, or licensed center-based childcare facilities (excluding home-based facilities) in Texas. The verification process helps maintain the integrity of the Registry and ensures that only qualified individuals are listed.
			`,
		},
		{
			title: 'Who qualifies to serve as a Reference? ',
			content: `
			A qualified Reference must be a current employee of the district or campus where the consulting services were provided. This individual should have been responsible for overseeing the fulfillment of the contract and can verify the Consultant’s work. If the original point of contact is no longer employed, another current employee from the same agency may serve as the Reference so long as they are qualified to speak on the contract and the specifics of the services provided. 
			<br />
			<br />
			Consultants who have worked with multiple districts must provide a Reference from each district where services were rendered.
			`,
		},
		{
			title: 'What are Reference(s) asked?',
			content: `
			The Reference will confirm that the consulting services were rendered, the duration of contract(s), adherence to the timeline, fulfillment of agreed services, and whether the Consultant is eligible for rehire. 
			<br />
			<br />
			Please visit <a href="/about/References" target="_blank" rel="noopener noreferrer"><b>About-References</b></a> for more information.
			`,
		},
		{
			title: 'Can Consultants be registered in both Disciplines?',
			content: `
       Yes, Consultants may apply to the Audit Discipline and EOP Discipline and must satisfy the requirements for each.
      `,
		},
		{
			title: 'Can I market or promote my Registry status?',
			content: `
      Consultants can market their Registry status, including Disciplines, however, Consultants are prohibited from claiming affiliation or endorsement by the TxSSC. Furthermore, Consultants shall not display or advertise the TxSSC name, logo, or likeness.
      `,
		},
		{
			title:
				'Why is School Behavioral Threat Assessment (SBTA) no longer offered as a Discipline?',
			content: `
			The Texas School Safety Center (TxSSC) recognizes that threat assessment teams have ample access to evidence-based threat assessment training, implementation, documentation, data reporting, and file storage support and resources through aligned collaboration between the TxSSC, the Texas Education Agency’s (TEA) Office of School Safety and Security, and all 20 regional Education Service Centers.
			<br />
			<br />
			Threat assessment teams at independent school districts and open-enrollment charter schools* are mandated by Texas Education Code (TEC) 37.11(c)(2) to receive evidence-based threat assessment training from the TxSSC or regional Educational Service Centers. The TxSSC also offers tiered training and consultation beyond the basic mandated SBTA training, including:
			<br />
			<br />
			<ul>
				<li>
					Intermediate SBTA
				</li>
				<li>
					Advanced SBTA
				</li>
				<li>
				 Operationalizing SBTA
				</li>
				<li>
					Digital Threat Assessment
				</li>
				<li>
					Advanced Threat Assessment
				</li>
			</ul>
			<br />
			Also, in accordance with TEC 37.1083, independent school districts and open-enrollment charter schools are required to submit school behavioral threat assessment documentation to TEA’s statewide Sentinel software. Sentinel creates a one-stop-shop for districts to collaborate with Office of School Safety and Security sector agents for additional support and resources.
			<br />
			<br />
			Furthermore, the TxSSC takes the stance that Registry Consultants should not participate in threat assessments and have access to student information that is protected by the Family Educational Rights and Privacy Act (FERPA).
			<br />
			<br />
			*Junior colleges are not mandated by Texas Education Code to participate in school behavioral threat assessment.
			`,
		},
		{
			title: "Why is 'Other' no longer offered as a Discipline?",
			content: `
			By narrowing the focus to the Audit and Emergency Operations Plan Disciplines, the Texas School Safety Center can ensure that all registered Consultants continue to provide knowledgeable and effective school safety or security consulting services.
			`,
		},
		{
			title: 'How do I file a complaint regarding a Consultant?',
			content: `
      If there is reason to believe a Consultant should not be included on the Registry for a disqualifying reason, please review <a href="/about/Consultant-conduct" target='_blank' rel='noopener noreferrer'><b>About-Consultant Conduct</b></a> and <a href="/background/disqualifiers" target='_blank' rel='noopener noreferrer'><b>Background-Disqualifiers</b></a> prior to reporting a complaint. 
			<br />
			<br />
			Please visit <a href="/contact/complaints" target='_blank' rel='noopener noreferrer'><b>Contact Us-File a Complaint</b></a> for more information.
      `,
		},
	];

	return (
		<div className='faqs'>
			<Masthead heading1='FAQs' heading2='General' />

			<ol className='expander'>
				{faqSections.map((section, index) => (
					<li key={index} className='toc-section'>
						<div className='toc-header' onClick={() => toggleSection(index)}>
							<h3>{section.title}</h3>
							<i
								className={`fa fa-chevron-${
									expandedSections[index] ? 'down' : 'up'
								}`}></i>
						</div>
						{expandedSections[index] && (
							<div
								className='expandable'
								dangerouslySetInnerHTML={{ __html: section.content }}></div>
						)}
					</li>
				))}
			</ol>
			<div className='tool-tip'>
				<p className='tip-text'>
					<i className='fa fa-arrow-up'></i> Click on the section title for more
					info.
				</p>
			</div>
		</div>
	);
};

export default General;
