import React from 'react';
import Masthead from '../../components/Masthead';
import useExpander from '../../hooks/useExpander';

function Background() {
	const [expandedSections, toggleSection] = useExpander();

	const faqSections = [
		{
			title: 'What is the Background Check process?',
			content: `
				All Applicants to the Texas School Safety and Security Consultant
				Registry (Registry) must undergo a background check. Background checks conducted for previous or current employment cannot be considered. The background
				check consists of the following:
			<ul>
				<li>
					Fingerprint-based criminal history review (at Applicant's expense)
				</li>
				<li>
					<a href='https://www.nsopw.gov/' target='_blank' rel='noopener noreferrer'>National Sex Offender Public Website</a> review
				</li>
				<li>
					<a href='https://tealprod.tea.state.tx.us/DNH/Public/SearchPerson' target='_blank' rel='noopener noreferrer'>Texas Education Agency Do Not Hire Registry</a> review
				</li>
				<li>
					<a href='https://tealprod.tea.state.tx.us/ECOS-External/EcosOnline/VirtCert' target='_blank' rel='noopener noreferrer'>State Board for Educator Certification</a> review
				</li>
				<li>
					Litigation review via the Texas Appeals Management and eFiling System
					(<a href='https://search.txcourts.gov/CaseSearch.aspx?coa=cossup' target='_blank' rel='noopener noreferrer'>TAMES</a>) and Public Access to Court Electronic Records (<a href='https://pacer.uscourts.gov/' target='_blank' rel='noopener noreferrer'>PACER</a>)
				</li>
			</ul>

			Upon review and approval of a General Application, Applicants will receive the background check instructions and required service code unique to the Texas School Safety Center (TxSSC). 
			<br /> 
			<br />
			The service code allows the TxSSC to review the results of an Applicant's fingerprint-based background check.  TxSSC staff review the Summary of Disqualifiers to determine if an Applicant or Consultant is ineligible for inclusion on the Registry. 
			<br /> 
			<br />
			Please visit <a href="/background/process" target='_blank' rel='noopener noreferrer'><b>Background-Check Process</b></a> for more information.
			`,
		},
		{
			title:
				'How do my fingerprints get sent to the Texas School Safety Center?',
			content: `
			IdentoGo, the Texas Department of Public Safety (DPS) fingerprint vendor, is the sole company DPS contracts with to accept electronic fingerprints for the purpose of criminal history checks. Upon review and approval of the General Application, the Applicant will receive the background check instructions and required service code unique to the TxSSC.
			<br />
			<br />
			The service code allows the TxSSC to review the results of an Applicant's fingerprint-based background check. TxSSC staff review the Summary of Disqualifiers to determine if an Applicant or Consultant is ineligible for inclusion on the Registry.
			<br />
			<br />
			<b>Background checks conducted for previous or current employment cannot be considered</b>.
		`,
		},
		{
			title: 'How do I schedule a fingerprint appointment?',
			content: `
			Upon review and approval of the General Application, the Applicant will receive step-by-step background check instructions from <a href="mailto:txssc_registry@txstate.edu"><b>txssc_registry@txstate.edu</b></a>.
			<br />
			<br />
			These step-by-step instructions include how to schedule your appointment, fee information, what to bring to your appointment, and the service code unique to the TxSSC. If the Applicant does not provide the service code at the time of scheduling, the TxSSC will not be able to review your background check and you will need to complete another appointment with IdentoGo which may incur an additional cost to the Applicant. 
		`,
		},
		{
			title:
				'How long does it take for the Texas School Safety Center to process my fingerprint-based criminal history review?',
			content: `
			The TxSSC typically receives fingerprint results from DPS within five business days if the appointment was completed in Texas. If you have completed a fingerprinting appointment but have not received correspondence from the TxSSC with next steps within ten business days, please reach out to <a href='mailto:txssc_registry@txstate.edu'><b>txssc_registry@txstate.edu</b></a>.
		`,
		},
		{
			title:
				"How will the TxSSC verify complaints or pending litigation relating to the person's provision of school safety or security consulting services?",
			content: `
			The Texas School Safety Center utilizes the Texas Appeals Management and eFiling System (TAMES) and Public Access to Court Electronic Records (PACER). 
			<br />
			<br />
			The TxSSC may use discretion to disqualify an Applicant or Consultant from the Registry for any civil or criminal litigation involving business practices that may impede the ability to provide contracting services to school districts. Applicants and Consultants are entitled to appeal a denial decision made by the TxSSC.
			<br />
			<br />
			Please visit <a href="/background/process" target='_blank' rel='noopener noreferrer'><b>Background-Check Process</b></a> for more information.
			`,
		},
		{
			title: 'What is the Summary of Disqualifiers?',
			content: `
			The Summary of Disqualifiers is a reference guide outlining background check history that may disqualify an Applicant or Consultant from the Registry. The Summary of Disqualifiers is not exhaustive, and the Texas School Safety Center (TxSSC) may use discretion to disqualify an Applicant or Consultant from the Registry. Applicants and Consultants are entitled to appeal a denial decision made by the TxSSC.
			<br />
			<br />
			Please visit <a href="/background/disqualifiers" target='_blank' rel='noopener noreferrer'><b>Background-Disqualifiers</b></a> for more information.
		`,
		},
		{
			title: 'Can I appeal the determination of my background check?',
			content: `
			Yes. If you believe the determination was made in error, please follow the Appeal Process provided in the background check status email from <a href="mailto:txssc_registry@txstate.edu"><b>txssc_registry@txstate.edu</b></a>. 
			<br />
			<br />
			Please visit <a href="/background/appeal" target='_blank' rel='noopener noreferrer'><b>Background-Appeal Process</b></a> for more information.
		`,
		},
	];

	return (
		<div className='faqs'>
			<Masthead heading1='FAQs' heading2='Background Check' />

			<ol className='expander'>
				{faqSections.map((section, index) => (
					<li key={index} className='toc-section'>
						<div className='toc-header' onClick={() => toggleSection(index)}>
							<h3>{section.title}</h3>
							<i
								className={`fa fa-chevron-${
									expandedSections[index] ? 'down' : 'up'
								}`}></i>
						</div>
						{expandedSections[index] && (
							<div
								className='expandable'
								dangerouslySetInnerHTML={{ __html: section.content }}></div>
						)}
					</li>
				))}
			</ol>
			<div className='tool-tip'>
				<p className='tip-text'>
					<i className='fa fa-arrow-up'></i> Click on the section title for more
					info.
				</p>
			</div>
		</div>
	);
}

export default Background;
