import React from 'react';
import Masthead from '../../components/Masthead';

const Update = () => {
	return (
		<div className='contact'>
			<Masthead heading1='Contact Us' heading2='Update Information' />
			<p>
				The following information can be updated via the Update Information
				Form:
			</p>
			<ul>
				<li>Contact Information</li>
				<li>Region(s) Served</li>
				<li>Years of Experience</li>
				<li>Audit Discipline updates</li>
				<li>Emergency Operations Plan (EOP) Discipline updates</li>
			</ul>
			<p className='cta-button inactive left-align'>
				Update Information Form coming soon
			</p>
		</div>
	);
};

export default Update;
