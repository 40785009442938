import React from 'react';
import Masthead from '../../components/Masthead';

const References = () => {
	return (
		<div className='about'>
			<Masthead heading1='About' heading2='Reference Verification' />

			<p>
				Reference verification ensures that the professional services provided
				aligns with the required standards and were performed in independent
				school district, open-enrollment charter schools, junior colleges,
				universities, private schools, or licensed center-based childcare
				facilities (excluding home-based facilities) in Texas. The verification
				process helps maintain the integrity of the Registry and ensures only
				qualified individuals are listed on the Registry.
			</p>

			<p>
				Only staff that were responsible for ensuring the contract was fulfilled
				can serve as references. A qualified reference must be a current
				employee of the district or campus where the consulting services were
				provided. This individual should have been responsible for overseeing
				the fulfillment of the contract and can verify the Consultant's work. If
				the original point of contact is no longer employed, another current
				employee from the same agency may serve as the reference so long as they
				are qualified to speak on the contract and the specifics of services
				provided. Consultants who have worked with multiple districts must
				provide a reference from each district where services were rendered.
			</p>

			<p>
				TxSSC staff will contact the references provided by the Applicant from
				their submitted Discipline Application.
			</p>

			<p>
				Reference(s) are required to verify the accuracy of a Consultant's
				reported experience and will confirm that consulting services were
				rendered, the duration of contract(s), adherence to the timeline,
				fulfillment of agreed services, and whether the Consultant is eligible
				for rehire.
			</p>

			<p>
				Please ensure your reference(s) knows that the TxSSC will be in contact.
				A lack of response will delay the completion of the Registration Process
				and your status as a Registered Consultant.
			</p>
			<p className='cta-button inactive left-align'>
				Reference Check Form coming soon
			</p>
		</div>
	);
};

export default References;
