import React from 'react';
import Masthead from '../../components/Masthead';

const Appeal = () => {
	return (
		<div className='background'>
			<Masthead heading1='Background' heading2='Appeal Process' />

			<p>
				The Texas School Safety Center (TxSSC) reserves the right to refuse,
				suspend, or revoke Registry status based on disqualifying event(s)
				identified during the initial review or at any time as an actively
				registered Consultant.
			</p>

			<p>
				Applicants denied placement on the Registry due to their background
				check have a right to appeal the decision made by the TxSSC.
			</p>

			<p>
				Consultants who have been suspended or removed from the Registry due to
				disqualifying event(s) have the right to appeal a denial decision made
				by the TxSSC.
			</p>

			<p>
				Applicants and Consultants will receive notification regarding their
				background check status via email and will be provided with a unique
				link to file an appeal. An appeal must be submitted within thirty (30)
				calendar days from the date the email notification is sent by the TxSSC.
				Failure to appeal within the stated timeframes will be considered
				uncontested and final.
			</p>

			<h2>Sole Appeal</h2>

			<p>
				The appeal will be reviewed by the School Safety Readiness Associate
				Director and Senior Specialist within fifteen (15) business days of
				receipt of the appeal.
			</p>

			<p>
				Within fifteen (15) business days from review, the decision to uphold,
				modify, or overturn the background determination will be provided via
				email. This is a final and binding decision.
			</p>

			<p>
				Applicants and suspended Consultants are eligible to restart the
				Registration Process when a disqualifier(s) is rectified.
			</p>
		</div>
	);
};

export default Appeal;
