import React from 'react';

import Masthead from '../../components/Masthead';

const Process = () => {
	return (
		<div className='background'>
			<Masthead heading1='Background' heading2='Background Check Process' />

			<p>
				All Applicants to the Texas School Safety and Security Consultant
				Registry (Registry) must undergo a background check. Background checks
				conducted for previous or current employment cannot be considered. The
				background check consists of the following:
			</p>

			<ul>
				<li>
					Fingerprint-based criminal history review (at Applicant's expense)
				</li>
				<li>
					<a
						href='https://www.nsopw.gov/'
						target='_blank'
						rel='noopener noreferrer'>
						National Sex Offender Public Website
					</a>{' '}
					review
				</li>
				<li>
					<a
						href='https://tealprod.tea.state.tx.us/DNH/Public/SearchPerson'
						target='_blank'
						rel='noopener noreferrer'>
						Texas Education Agency Do Not Hire Registry
					</a>{' '}
					review
				</li>
				<li>
					<a
						href='https://tealprod.tea.state.tx.us/ECOS-External/EcosOnline/VirtCert'
						target='_blank'
						rel='noopener noreferrer'>
						State Board for Educator Certification
					</a>{' '}
					review
				</li>
				<li>
					Litigation review via the Texas Appeals Management and eFiling System
					(
					<a
						href='https://search.txcourts.gov/CaseSearch.aspx?coa=cossup'
						target='_blank'
						rel='noopener noreferrer'>
						TAMES
					</a>
					) and Public Access to Court Electronic Records (
					<a
						href='https://pacer.uscourts.gov/'
						target='_blank'
						rel='noopener noreferrer'>
						PACER
					</a>
					)
				</li>
			</ul>

			<p>
				Upon review and approval of a General Application, Applicants will
				receive the background check instructions and required service code
				unique to the Texas School Safety Center (TxSSC). The service code
				allows the TxSSC to review the results of an Applicant's
				fingerprint-based background check. TxSSC staff review the Summary of
				Disqualifiers to determine if an Applicant or Consultant is ineligible
				for inclusion on the Registry.
			</p>

			<p>
				The Summary of Disqualifiers is a reference guide outlining background
				check history that may disqualify an Applicant or Consultant from the
				Registry. The Summary of Disqualifiers is not exhaustive, and the TxSSC
				may use discretion to disqualify an Applicant or Consultant from the
				Registry. Please visit{' '}
				<a href='/background/disqualifiers'>
					<b>Background-Disqualifiers</b>
				</a>{' '}
				for more information.
			</p>

			<p>
				Applicants and Consultants are entitled to appeal a denial decision made
				by the TxSSC. Please visit{' '}
				<a href='/background/appeal'>
					<b>Background-Appeal Process</b>
				</a>{' '}
				for more information.
			</p>
		</div>
	);
};

export default Process;
